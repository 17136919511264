@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.price {
  display: flex;
  flex-flow: wrap row;
  justify-content: space-between;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
}

.wrapperComponent {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  border-left: 1px solid $color-gray-border;

  @include breakpoint(md) {
    border-left: none;
  }

  .mainContent {
    padding-top: 25px;
    padding-left: 12px;
    width: 100%;
    flex: 1;

    @include breakpoint(sm) {
      padding-top: 0;
    }

    @include breakpoint(md) {
      padding-left: 0;
      margin: 8px;
      width: calc(100% - 16px);
    }

    .promotion {
      margin-left: auto;
      border: 1px solid $color-blue-promotion;
      color: $color-blue-promotion;
      padding: 8px 40px;
      margin-top: 24px;
      width: fit-content;
    }

    .actionsWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      margin-bottom: 18px;

      .quantityCounter {
        width: 114px;
        height: 54px;
        background-color: $color-white;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid $color-gray-border;

        @include breakpoint(sm) {
          min-width: 114px;
        }

        input {
          background-color: $color-white;
        }

        button {
          width: 30px;
        }
      }

      .addToCartButton {
        width: 100%;
        background-color: #ed7332;
        border-color: #ed7332;

        * {
          color: $color-text-dark;
        }

        > button {
          width: 100%;
          justify-content: flex-end;
          text-align: center;
          border-right: 1px solid #d4672d;

          > span {
            display: flex;
            width: 100%;
            justify-content: center;
          }
        }
      }

      .loginButton {
        text-decoration: none;
        color: $color-primary;
        margin-right: 64px;

        @include breakpoint(md) {
          margin-right: 16px;
        }
      }
    }
  }
}

.text {
  font-family: $font-rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: $color-text-dark;
  margin-bottom: 4px;
}

.groupPrice {
  display: flex;
  flex-flow: wrap column;
  align-items: flex-end;
}

.old {
  display: inline;
  margin-right: 14px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: line-through;
  color: $color-label;
}

.net {
  display: block;
  font-family: $font-rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  color: $color-text-dark;

  strong {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 38.4px */
    letter-spacing: 0.32px;
  }
}

.gross {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  color: $color-text-dark;
}

.tableWrapper {
  padding-top: 32px;

  table {
    width: 100%;

    th {
      font-weight: 400;
      text-align: right;
      padding-bottom: 8px;

      span {
        display: inline-block;
        font-size: 12px;
        color: $color-text;
      }
    }

    td {
      text-align: right;
      padding: 8px 0;
      border-top: 1px dashed $color-gray-border;
      color: $color-text;
    }

    .left {
      text-align: left;
    }

    .nameWrapper {
      display: inline-flex;
      align-items: center;
    }

    .current {
      td {
        font-weight: 700;
        color: $color-text-dark;
      }
    }
  }
}

.dashedBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 12px;
  width: calc(100% - 12px);
  border-top: 1px dashed $color-gray-border;
  min-height: 48px;
  padding: 10px 0;

  @include breakpoint(md) {
    padding: 4px 8px;
  }

  @include breakpoint(sm) {
    margin-left: 0;
    width: 100%;
  }

  .item {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    color: $color-secondary;
    height: 100%;

    &:not(&:first-child) {
      border-left: 1px solid $color-gray-border;
      padding-left: 10px;
    }

    &:not(&:lasr-child) {
      padding-right: 5px;
    }

    > div {
      margin-right: 0;
    }

    strong {
      margin: 0 4px;
    }

    &:last-child {
      margin-right: 0;
    }

    > svg {
      margin-right: 8px;
    }

    &.button {
      cursor: pointer;

      > svg {
        color: $color-secondary;
      }
    }
  }

  .delivery {
    color: $color-text;

    &.empty {
      width: calc(33% - 15px);

      & + div {
        border-left: unset !important;
      }
    }
  }

  .shoppingList {
    > div > div {
      margin-top: 0;
      color: $color-secondary;
    }
  }

  .favourite {
    &.isOnFavouriteShoppingList {
      svg {
        g {
          fill: $color-secondary;
        }
      }
    }
  }
}

.availabilityButton {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: $color-secondary;
  background-color: transparent;
  width: 100%;
  padding: 12px 0;
  border: 1px solid $color-secondary;
  border-radius: 4px;
  cursor: pointer;
  max-height: 44px;
  margin-bottom: 18px;
  margin-top: 8px;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.warehouseWrapper {
  margin-top: 45px;

  @include breakpoint(sm) {
    margin-top: 0;
  }

  > div:nth-child(2) {
    > div > div {
      min-height: 44px;
    }
  }
}

.warehouseTitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: $color-text-dark;
  margin-bottom: 4px;
}

.unitWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: center;
    }

    img {
      margin-right: 4px;

      + span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        color: $color-text;
      }
    }
  }

  .unitWrapperRight {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;

    &.hasQty {
      color: $color-text-dark !important;
    }

    span {
      &#noLabel {
        color: #d92d05 !important;
      }
    }
  }
}

.dash {
  display: block;
  height: 10px;
  width: 1px;
  background-color: #a1a4ab;
  margin-left: 8px;
  margin-right: 10px;
}
