@import 'src/theme/color';

.wrapperComponent {
  display: block;
  max-width: 360px;
  width: 100%;
  margin-top: 20px;
  grid-area: menu;
}

.info {
  display: flex;
  align-items: flex-start;
  color: $color-info;
  padding: 8px;
  background: $color-gray-background;
  border: 1px solid $color-secondary-background;
  box-shadow: 0 1px 2px rgba(6, 25, 56, 0.05);
  border-radius: 2px;

  svg {
    flex-shrink: 0;
    margin-right: 12px;
  }
}

.category {
  position: relative;
  padding: 0;
  display: flex;
  justify-content: space-between;

  &:hover {
    background-color: $color-orange;
  }

  a {
    padding: 8px 16px;
    display: block;
    width: 100%;
    border: none;
    background: transparent;
    cursor: pointer;
    text-align: left;
    font-size: 16px;
    color: initial;
    text-decoration: none;
    display: inline-block;
    white-space: break-spaces;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    color: $color-text-dark;

    &.chosen {
      color: $color-secondary;
      font-weight: 700;
    }

    .counter {
      font-size: 12px;
    }
  }

  svg {
    margin-right: 10px;
  }
}

.label {
  border-radius: 8px;
  background: $color-gray-label;
  padding: 20px 16px;
  margin-bottom: 23px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  letter-spacing: 0.24px;
}

.subMenu {
  left: 100%;
  z-index: 9999;
  max-width: 360px;
  width: 100%;
  border-radius: 8px;
  background: #f2f2f2;
  box-shadow: 2px 0px 25px 0px rgba(0, 0, 0, 0.15);

  ul {
    margin-top: 0;
  }

  .image {
    img {
      display: block;
      width: 100%;
    }

    &:hover {
      background-color: initial;
    }
  }
}
