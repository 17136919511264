@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 950px;
  max-width: 90vw;
  max-height: 90vh;
  overflow: auto;
  background-color: $color-gray-background;
  padding: 24px;
  border-radius: 8px;
  outline: none;

  &.colorWhite {
    background-color: $color-white;
  }

  @include breakpoint(md) {
    min-width: auto;
    border-radius: 0;
  }

  &.fullScreen {
    width: 100%;
    height: 100%;
    min-width: auto;
    max-width: calc(100vw - 8px);
    max-height: calc(100vh - 8px);
  }

  &.fullWidth {
    width: 100%;
    height: 100%;
    min-width: auto;
    max-width: calc(90vw);
    max-height: calc(80vh);
  }
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;
}

.close {
  color: $color-text;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.title {
  color: $color-text-dark;
  font-family: $font-rubik;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.32px;
}
