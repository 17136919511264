@import 'src/theme/color';
@import 'src/theme/font';

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

.searchResults {
  border: 1px solid $color-search-border;
  background: $color-white;
  width: 700px;
  height: 500px;
  position: absolute;
  top: 105px;
  // left: 50%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  .products {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;

    .list {
      overflow: auto;
      width: 100%;

      .unit-selector-wrapper {
        display: none;
      }

      .notFound {
        width: 100%;
        text-align: center;
        margin-top: 50px;
      }
    }

    .showAllButton {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      cursor: pointer;
      color: $color-secondary;
      padding: 12px 0;
      background-color: $color-gray-background;
      border: none;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
      margin-bottom: 4px;
    }
  }

  .categories {
    width: 285px;

    border-left: 1px solid $color-gray-border;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .title {
      font-family: $font-rubik;
      font-weight: 700;
      font-size: 18px;
      height: 50px;
      margin: 16px;
      color: $color-label;
    }

    .list {
      overflow: auto;
      width: 100%;
      padding: 0 16px 16px;

      .category {
        display: block;
        margin-bottom: 8px;
        color: $color-text-search;
        text-decoration: none;
        border: none;
        background: transparent;
        padding: 0;
        cursor: pointer;

        .arrow {
          display: none;
          margin-right: 24px;
          color: $color-text-dark;
        }

        &:hover {
          color: $color-secondary;
        }

        span {
          color: $color-gray-checkbox;
        }
      }
    }
  }
}
