@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  width: 100%;
  margin-bottom: 30px;
  background-color: $color-white;
  border-radius: 4px;
  position: relative;

  .mainImage {
    margin: 0 auto;
    display: block;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }

  .secondaryImages {
    position: absolute;
    right: 25px;
    top: 25px;
    max-width: 230px;
    display: flex;
    flex-direction: column;

    @include breakpoint(sm) {
      right: 5px;
      top: 5px;
      max-width: 100px;
      width: 100%;
    }
  }

  .secondaryImage {
    margin-bottom: 15px;

    img {
      display: block;
      width: 100%;
      cursor: pointer;
    }
  }

  .imageName {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
    letter-spacing: 0.24px;
    margin-bottom: 4px;

    @include breakpoint(sm) {
      font-size: 16px;
    }
  }
}
