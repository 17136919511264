@import 'src/theme/color';
@import 'src/theme/breakpoints';

.mobileList {
  .mobileListItem {
    padding: 16px;
    border-bottom: 1px solid $color-gray-border;
    background: $color-white;

    &:nth-child(even) {
      background: $color-gray-background;
    }

    .line {
      border-bottom: 1px solid $color-gray-border;
      padding: 4px 0;
      display: flex;
      align-items: flex-start;

      @include breakpoint(md) {
        justify-content: space-between;
        width: 100%;
      }

      &:last-child {
        border-bottom: none;
      }

      .label {
        font-weight: 600;
      }

      > div {
        margin: 0 4px;
        width: 50%;

        @include breakpoint(md) {
          width: 100%;
        }
      }
    }
  }
}

.table {
  width: 100%;
  color: $color-text-dark;
  font-size: 12px;
  border-collapse: separate;
  border-spacing: 0 4px;
}

.th {
  padding: 10px 8px;
  font-weight: 400;
  background: $color-gray-background-dark;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: $color-text;

  &.cursor-pointer {
    cursor: pointer !important;
  }

  &.text-align-center {
    text-align: center;
  }
}

tr {
  background-color: $color-gray-background;

  td:first-child {
    border-radius: 4px 0 0 4px;
  }

  td:last-child {
    border-radius: 0 4px 4px 0;
  }
}

.td {
  padding: 10px 8px;
  font-weight: 400;
  background: $color-white;

  &.text-align-left {
    text-align: left;
  }
}

.sortWrapper {
  margin-right: 4px;
  display: inline-block;
  vertical-align: middle;
}

.sortArrow {
  &.down {
    transform: rotate(180deg);
  }

  &.current {
    color: $color-secondary;
  }
}
