@import 'src/theme/color';

.wrapperComponent {
  table {
    border-spacing: 0;

    th {
      background-color: $color-gray-background;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
      color: $color-text-dark;
    }

    td {
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
    }
  }

  &.isCart {
    table {
      margin-top: -4px;
      thead {
        box-shadow: 0px 2px 5px 0px rgba(6, 25, 56, 0.06);
      }

      th {
        padding: 6px 8px;
        background-color: $color-gray-background;
      }

      border-collapse: separate;
      border-spacing: 0 4px;
    }
  }
}

.productThumb {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.productInfo {
  a {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    color: $color-text-dark;
    text-decoration: none;
  }

  .grey {
    font-weight: 400;
    color: $color-text;
    font-size: 10px;
    margin-bottom: 4px;
  }
}

.quantityCellWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > div + div {
    margin-top: 10px;
  }
}

.productPrice {
  font-weight: 300;

  .small {
    color: $color-text;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
  }
}

.productTotalPrice {
  font-weight: 700;

  .suffix {
    color: $color-text;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
  }

  .small {
    color: $color-text;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
  }
}

.productUnit {
  color: $color-text;

  .descriptionLine {
    display: block;
    font-size: 10px;
  }
}

.availabilityWrapper {
  display: flex;
  align-items: center;

  > span {
    margin-left: 5px;
    font-size: 12px;
    line-height: 14px;
  }
}

.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  > svg {
    width: 16px;
    height: 16px;
    cursor: pointer;

    &:first-child {
      margin-bottom: 8px;
    }
  }
}

.confirmationModal {
  text-align: left;
}

.confirmationModalActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;

  button + button {
    margin-left: 16px;
  }
}

.dropdownButton {
  border: none;
  background: transparent;
  margin-left: 10px;
  position: relative;
  cursor: pointer;

  &:after {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 6px 5px;
    border-color: transparent transparent $color-gray-border transparent;
    position: absolute;
    top: 11px;
    right: -10px;
    transform: rotate(180deg);
  }

  &.open:after {
    transform: rotate(0);
    border-color: transparent transparent $color-secondary transparent;
  }
}

.menu {
  .MuiPaper-root {
    box-shadow: none;
    border: 1px solid $color-secondary-border;
  }
}

.menuItem {
  &.MuiMenuItem-root {
    color: $color-text;
  }

  &.MuiMenuItem-root:hover {
    color: $color-secondary;
    background-color: $color-gray-background;
  }

  > svg {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
}

.labels {
  margin-top: 8px;
}

.list {
  padding: 0;
  margin: 4px 0;
}

.listItem {
  position: relative;
  display: inline-block;
  width: auto;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  color: $color-text;

  &:not(:last-child) {
    padding-right: 2px;
    margin-right: 4px;

    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 5px);
      right: 0;
      width: 1px;
      height: 10px;
      background-color: $color-text;
    }
  }

  strong {
    display: inline;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: $color-text;

    a {
      text-decoration: none;
      color: $color-secondary;
    }
  }
}
