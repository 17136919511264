@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  position: relative;
  padding: 0 8px;

  &.isLoading {
    opacity: 0.3;
  }

  .navButton {
    height: 49px;
    width: 49px;
    border: none;
    border-radius: 100%;
    border: 1px solid $color-secondary;
    background-color: $color-white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 40%;
    z-index: 99;

    &:disabled {
      display: none;
    }

    &.back {
      left: 20px;
      transform: rotate(90deg);
    }

    &.next {
      right: 20px;
      transform: rotate(-90deg);
    }

    svg {
      font-size: 24px;
    }
  }

  .trayBar {
    display: flex;
    margin-top: 27px;
    border: none;

    button {
      border-left: none;
      border-right: none;
      width: 100%;
      height: 4px;
      border-top: none;
      border-bottom: none;

      &:first-child {
        border-radius: 100px 0 0 100px;
      }

      &:last-child {
        border-radius: 0 100px 100px 0;
      }

      &:disabled {
        background-color: $color-faq-active;
        border-radius: 100px;
      }
    }
  }

  .slide {
    height: 570px;
    outline: none;

    .productWrapper {
      height: 570px;
      padding: 6px;
    }
  }
}
