@import 'src/theme/color';
@import 'src/theme/font';

.button {
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: $color-black;
  background: $color-primary;
  border: 1px solid $color-primary;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  font-family: $font-rubik;

  .label {
    display: inline-flex;
    align-items: center;

    svg {
      margin: 0 4px;
    }
  }

  &.secondary {
    background: $color-secondary;
    border: 1px solid $color-secondary;
    color: $color-white;
  }

  &.danger {
    background: $color-red;
    border: 1px solid $color-red;
  }

  &.gray {
    background: darken($color: $color-gray-border, $amount: 18%);
    border: 1px solid darken($color: $color-gray-border, $amount: 18%);
  }

  &.large {
    height: 50px;
    font-size: 18px;
  }

  &.disabled {
    background-color: $color-gray-background-dark;
    color: $color-gray-checkbox;
    border: 1px solid $color-gray-background-dark;
    cursor: default;
  }

  &.loading {
    .label {
      visibility: hidden;
    }
  }

  &.ghost {
    background: transparent;
    border: 1px solid $color-primary;
    color: $color-primary;

    &.secondary {
      border: 1px solid $color-secondary;
      color: $color-secondary;
    }

    &.danger {
      border: 1px solid $color-red;
      color: $color-red;
    }

    &.gray {
      border: 1px solid darken($color: $color-gray-border, $amount: 18%);
      color: darken($color: $color-gray-border, $amount: 18%);
    }
  }

  .progress {
    color: $color-white;
    position: absolute;
    width: 25px !important;
    height: 25px !important;

    &.ghost {
      color: $color-primary;

      &.secondary {
        color: $color-secondary;
      }

      &.danger {
        color: $color-red;
      }
    }
  }
}
