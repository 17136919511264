@import 'src/theme/color';
@import 'src/theme/breakpoints';
@import 'src/theme/font';

.wrapperComponent {
  display: block;
}

.form {
  display: flex;
  justify-content: space-between;
  border: 1px solid $color-form-border;
  border-radius: 4px;
  background-color: $color-white;
  height: 36px;

  @include breakpoint(md) {
    background-color: $color-white;
  }

  .searchButton {
    background-color: transparent;
    width: 40px;
    border-left: 1px solid $color-gray-border;
    border-radius: 0;

    &:hover {
      background-color: transparent;
    }

    svg {
      font-size: 16px;
    }
  }
}

.input {
  width: 320px;
  height: 36px;
  padding: 0 16px;
  border: none;
  border-right: 1px solid $color-form-border;
  border-right: unset;
  color: $color-label;
  font-family: $font-rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  flex: 1;
  outline: none;
  background-color: transparent;

  @include breakpoint(md) {
    border-right: none;
  }
}
