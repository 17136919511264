@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: flex;
  flex-flow: wrap column;
  width: 100%;

  @include breakpoint(md) {
    padding-top: 61px;
  }
}

.text {
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  width: 100%;
  padding: 24px 0;
  font-size: 24px;
  font-weight: 500;
  background-color: $color-gray-border;
  color: $color-white;
}

.homePage {
  padding-bottom: 100px;
  display: grid;
  grid-template-areas:
    'header header header'
    'menu content content'
    'menu footer footer';
  grid-column-gap: 32px;
  grid-template-columns: 360px 1fr 1fr;
  grid-template-rows: auto auto 1fr;

  @media screen and (max-width: 1135px) {
    grid-template-columns: 260px 1fr 1fr;
  }

  @include breakpoint(sm) {
    display: block;
  }
}

.consentActions {
  margin-top: 20px;
}

.consentContent {
  max-height: 70px;
  overflow-y: scroll;
  padding: 8px;
  border: 1px solid $color-gray-border;
}

.consentRequired {
  margin-top: 15px;
}
