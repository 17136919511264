@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  border-left: 12px solid $color-secondary;
  padding: 24px 16px;
  background: $color-white;
  margin-bottom: 16px;

  @include breakpoint(lg) {
    padding: 21px 8px;
    border-left: 8px solid $color-secondary;
    background-color: $color-gray-background;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.labelBold {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: $color-text-dark;
}

.valueCartNet {
  color: $color-label;

  span {
    color: $color-text-dark;
    font-family: $font-rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
}

.valueCartGross {
  color: $color-label;

  span {
    color: $color-text-dark;
    font-weight: 300;
    font-size: 16px;
    margin-right: 4px;
  }
}

.valueTotalNet {
  color: $color-label;

  span {
    color: $color-text-dark;
    font-family: $font-rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
}

.valueTotalGross {
  color: $color-label;

  span {
    color: $color-text-dark;
    font-weight: 300;
    font-size: 18px;
    margin-right: 4px;
  }
}

.shipmentSection {
  border-top: 1px dashed $color-summary-border;
  border-bottom: 1px dashed $color-summary-border;
  margin: 12px 0;
  padding: 12px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  color: $color-text-dark;

  > div:first-child {
    margin-bottom: 10px;
  }

  .shipmentDetail {
    max-width: 125px;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    text-align: right;
  }
}

.buttonWrapper {
  margin-top: 32px;

  button {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    padding: 0 8px;

    .buttonArrow {
      font-size: 20px;
      margin-left: 16px;
    }
  }
}

.weightSection {
  margin-top: 17px;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  color: $color-text;
}
