@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  margin-top: 20px;
  grid-area: footer;
}

.productTabsWrapper {
  width: 100%;
  background-color: $color-white;

  .tabsBar {
    overflow-x: auto;
    display: flex;
    justify-content: flex-start;

    @include breakpoint(sm) {
      display: block;
    }
  }

  .tabs {
    width: fit-content;
    height: 61px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    background: $color-navy;
    margin-right: 10px;
    padding: 0 28px;
    border-radius: 4px 4px 0px 0px;

    @include breakpoint(sm) {
      padding: 0 10px;
      width: 100%;
      margin-right: 0;
    }
  }

  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 51px;
    font-size: 16px;
    color: $color-white;
    font-weight: 300;
    width: 169px;
    cursor: pointer;
    border-radius: 4px 4px 0px 0px;

    @include breakpoint(sm) {
      width: 100%;
      max-width: 110px;
    }

    &.active {
      color: $color-navy;
      background: $color-white;
      font-size: 18px;
      font-weight: 700;
      font-family: $font-rubik;
    }
  }
}
