@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;
  row-gap: 24px;
  width: 100%;

  @include breakpoint(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.content {
  display: flex;
  flex-flow: wrap column;
  flex: 1;
  align-items: center;
}

.name {
  margin-top: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  color: $color-text-dark;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  padding: 12px;
  border: 2px solid $color-gray-border;
}

.icon {
  display: block;
  width: 100%;
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border: 1px solid $color-gray-border;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;

  @include breakpoint(sm) {
    padding: 8px;

    > span > span {
      padding: 0;
    }
  }

  &:hover,
  &.active {
    border-color: $color-secondary;
  }
}

.cost {
  color: $color-text-dark;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
}
