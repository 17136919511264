@import "src/theme/color";

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  border: 1px solid $color-gray-border;
  padding: 8px 16px;
  font-size: 14px;
  color: $color-label;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  background: $color-gray;
  border-radius: 4px;

  span {
    text-align: left;
    width: 175px;
    color: $color-text-dark;

    &.placeholder {
      color: $color-text-light;
    }
  }

  svg {
    color: $color-secondary;
    font-size: 20px;
    margin-left: 16px;
  }
}
