@import 'src/theme/color';
@import 'src/theme/breakpoints';
@import 'src/theme/utilities';

.componentWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: $color-white;
  padding-top: $header-height-desktop;

  @include breakpoint(md) {
    padding-top: 0;
  }

  header {
    width: 100%;
    height: $header-height-desktop;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 110;
    background-color: $color-white;
    position: fixed;
    top: 0;
    left: 0;

    @include breakpoint(md) {
      height: auto;
    }
  }

  .content {
    width: 100%;
    min-height: calc(100vh - $header-height-desktop);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @include breakpoint(md) {
      margin-bottom: $bottom-menu-height;
    }

    > * {
      width: 100%;
    }
  }

  .backToTop {
    position: fixed;
    bottom: 25px;
    right: 25px;
    background: $color-secondary-background;
    color: $color-secondary;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    border: none;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;

    @include breakpoint(md) {
      bottom: 85px;
    }
  }
}
