@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
}

.row {
  width: 100%;
  border-bottom: 1px solid $color-gray-border;
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  padding-bottom: 30px;

  @include breakpoint(md) {
    display: block;
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.column {
  width: 50%;
  flex-shrink: 0;
  padding: 0 30px;
  color: $color-text;

  @include breakpoint(md) {
    width: 100%;
    padding: 16px 0;
  }

  &:last-of-type {
    border-right: none;
  }

  .title {
    color: $color-black;
    margin-bottom: 1em;
    font-weight: 700;
    color: $color-text-dark;

    @include breakpoint(md) {
      margin-bottom: 0.5em;
    }
  }

  .date {
    display: flex;
    align-items: center;

    .highlight {
      color: $color-secondary;
    }

    svg {
      margin-right: 12px;
      font-size: 20px;
    }
  }

  button {
    border: none;
    background: transparent;
    color: $color-secondary;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    margin-top: 6px;
    font-weight: 500;

    @include breakpoint(md) {
      margin-top: 0;
    }
  }
}
