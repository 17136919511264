@import 'src/theme/color';

.wrapperComponent {
  .shoppingListButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 0;
    background-color: transparent;
    width: auto;
    margin-left: auto;
    cursor: pointer;
    color: $color-text;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    color: $color-navy;
    margin-top: 12px;

    svg {
      color: $color-primary;
      font-size: 20px;
      margin-right: 6px;
    }
  }
}
