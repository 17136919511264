@import 'src/theme/color';

.wrapperComponent {
  margin-top: 30px;
  a {
    text-decoration: none;
  }

  .subcategory {
    > div:first-child {
      width: 100%;
      display: block;
      height: 0;
      padding-bottom: 100%;
      overflow: hidden;
      position: relative;
    }

    img {
      display: block;
      width: 100%;
    }

    .name {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      text-align: center;
      margin-top: 12px;
      color: $color-text-dark;
    }
  }
}
