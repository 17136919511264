@import 'src/theme/color';

.wrapperComponent {
  padding-bottom: 40px;

  a {
    text-decoration: none;
  }
  .image {
    max-width: 120px;
    display: block;
    width: 100%;
    margin: 0 auto 10px auto;
  }

  .name {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    color: $color-text-dark;
    text-align: center;
  }
}
