@import "src/theme/color";
@import "src/theme/font";
@import "src/theme/breakpoints";

.block {
  width: 461px;
  height: 557px;

  @include breakpoint(lg) {
    width: 100%;
    height: auto;
  }

  flex-shrink: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-left: 19px solid $color-block;
}

.title {
  padding: 31px 0 35px 24px;
  font-family: $font-rubik;
  font-weight: 700;
  font-size: 40px;
  color: $color-text-dark;
  background-color: rgba(141, 144, 150, 0.2);

  @include breakpoint(md) {
    padding: 11px 0 40px 21px;
  }
}

.menuCategory {
  padding: 40px 24px 24px 100px;
}

.label {
  font-family: $font-rubik;
  font-weight: 300;
  font-size: 24px;
  line-height: 120%;
  color: $color-text-dark;
  margin-bottom: 16px;
}

.menuItem {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: $color-text-dark;
  margin-bottom: 16px;

  svg {
    display: block;
    font-size: 20px;
    color: $color-secondary;
    margin-right: 20px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}

.content {
  width: calc(100% - 500px);

  @include breakpoint(lg) {
    width: 100%;
  }
}

.wrapperComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: $color-gray;

  @include breakpoint(lg) {
    display: block;
  }

  &.onlySlider {
    flex-direction: column;
    padding-bottom: 64px;

    .block {
      width: 100%;
      height: auto;
      border-left: none;
    }

    .title {
      background-color: transparent;
    }

    .content {
      width: 100%;
    }
  }

  &.withImage {
    .title {
      min-height: 187px;
      padding: 32px 24px 32px 100px;

      @include breakpoint(md) {
        padding: 58px 24px 32px 21px;
      }
    }
  }

  .seeAlsoButton {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    line-height: 140%;
    color: $color-text;
    padding: 36px 0 22px;

    .arrow {
      margin-left: 20px;
      color: $color-secondary;
    }

    &.active {
      .arrow {
        transform: rotate(180deg);
      }
    }
  }
}
