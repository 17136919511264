@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import 'src/theme/color';
@import 'src/theme/breakpoints';

html {
  min-height: 100vh;
}

body {
  height: 100vh;
  margin: 0;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $color-body-bg;
}

* {
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
}

#root {
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: $color-secondary-background;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: $color-secondary;
  border-radius: 8px;
}

@include breakpoint(md) {
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: unset;
  }

  ::-webkit-scrollbar-track {
    background-color: unset;
  }

  ::-webkit-scrollbar-thumb {
    background: unset;
    border-radius: 0;
  }
}

.focusRing___1airF.carousel__slide-focus-ring {
  outline: none !important;
}

.rdp-day_selected,
.rdp-day_range_start {
  border-radius: 100% !important;
}

.thin {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
.bold {
  font-weight: 700;
}

.MuiSwitch-root {
  .MuiSwitch-track {
    background-color: $color-primary;
  }
}

.StylePath-Pages-Home-components-Banner .carousel__dot--selected {
  font-weight: 800 !important;
  color: $color-slider-dot !important;
}

.SnackbarItem-message {
  white-space: pre-wrap;
}

.slick-dots {
  position: static;
  text-align: unset;

  li {
    width: unset;
    height: unset;
  }
}

.MuiTabs-indicator {
  height: 4px !important;
  background-color: $color-primary !important;
}

.MuiTab-root.Mui-selected {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 140% !important;
  color: $color-text-dark !important;
}

.htmlBlock {
  grid-area: content;

  > * {
    a,
    img {
      display: block;
      width: 100%;
    }
  }
}
