@import 'src/theme/color';

.wrapperComponent {
  display: inline-flex;
  align-items: center;
  margin: 0 4px 4px 0;
  padding: 4px 8px;
  border-radius: 4px;
  background: $color-primary;
  font-size: 12px;
  font-weight: 500;
  color: $color-white;
  max-height: 22px;

  svg {
    margin-right: 4px;
  }

  &.new {
    background: $color-new;
  }

  &.bestseller {
    background: $color-bestseller;
  }

  &.promotion {
    background: $color-red;
  }
  &.x_dodatkowykoszt {
    background: #ffe500;
    color: #090c0f;
  }

  &.x_gabaryt {
    background: #1fff2e;
    color: #090c0f;
  }

  &.x_szyba {
    background: #2ee0ff;
    color: #090c0f;
  }
}
