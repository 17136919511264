@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  background: $color-gray-background;
  width: 100%;
  padding-bottom: 20px;

  .notFound {
    width: 100%;
    text-align: center;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 84px;

  @include breakpoint(lg) {
    height: auto;
    flex-direction: column;

    .checkoutContainer {
      background-color: $color-white;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: 13px 11px;
    }

    .link {
      order: 2;
      align-self: flex-start;
      padding: 10px;
    }
  }
}

.link {
  color: $color-navy;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  text-decoration: none;
}

.backIcon {
  margin-right: 8px;
}

.nextIcon {
  margin-right: 8px;
  transform: rotateY(180deg);
}

.contentWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include breakpoint(lg) {
    display: block;
  }
}

.content {
  flex: 1;

  @include breakpoint(lg) {
    .link {
      display: none;
    }
  }
}

.sidebar {
  position: sticky;
  width: 350px;
  top: 20px;
  margin-left: 16px;
  flex-shrink: 0;

  @include breakpoint(lg) {
    width: 100%;
    margin-left: 0;
    position: sticky;
    bottom: calc(-100vh + 100% - 190px);
    z-index: 10;
    background-color: $color-gray-background;
  }

  @media screen and (min-width: 900px) and (max-width: 1200px) {
    position: static;
    bottom: unset;
    margin-top: 10px;
  }
}

.additionalInformationDataWrapper {
  margin-bottom: 16px;
}
