@import 'src/theme/color';

.wrapperComponent {
  display: flex;
  position: absolute;
  top: 0;
  width: 950px;
  background-color: $color-gray-background;
  z-index: 999;
  border-radius: 4px;
  overflow: hidden;
  padding: 24px;

  > div {
    background-color: $color-gray-background;
  }

  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.24px;
    color: $color-text-dark;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .details {
    flex-wrap: wrap;
    margin-bottom: 8px;

    > span {
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
      color: $color-text;
      margin-right: 8px;
      position: relative;

      > span:nth-child(2) {
        font-weight: 500;
      }

      .separator {
        margin-left: 8px;
      }
    }

    .special {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      color: $color-secondary;
    }
  }

  .producer {
    color: $color-secondary;
  }

  .category {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    color: $color-text;
  }

  .categoryName {
    color: $color-secondary;
    font-weight: 500;
  }

  .producerLogo {
    max-width: 85px;
    margin: 16px 0;

    img {
      display: block;
      width: 100%;
    }
  }

  .prices {
    display: flex;
    justify-content: space-between;

    > div:nth-child(2) {
      text-align: right;
    }
  }

  .priceTitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-top: 16px;
    color: $color-text-dark;
    margin-bottom: 4px;
  }

  .price {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    color: $color-text-dark;

    span {
      font-size: 16px;
    }
  }

  .oldPrice {
    text-decoration: line-through;
  }

  .netPrice {
    font-size: 32px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 38.4px */
    letter-spacing: 0.32px;
  }

  .discountPrice {
    color: $color-red;
  }

  .description {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    color: $color-text-dark;
  }

  .descriptionTitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-top: 16px;
    color: $color-text-dark;
    margin-bottom: 4px;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.3);
  z-index: 998;
}

.galleryWrapper {
  > div > div {
    margin-top: 0 !important;
  }
}
