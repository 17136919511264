@import 'src/theme/color';
.container {
  height: 100%;
  .control {
    height: 100%;
    height: 36px;
    width: 100%;
    box-shadow: none;
    background-color: $color-white;
    flex-wrap: nowrap !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    border: none;

    svg {
      transform: rotateX(0);
    }

    &.controlOpen {
      background-color: $color-white;
      border-radius: 4px 4px 0 0;

      svg {
        transform: rotateX(180deg);
      }
    }
    &:hover {
      border-color: $color-table-border;
    }
  }

  .option {
    font-size: 16;
    padding: 8px 16px;

    color: $color-label;
    background-color: $color-white;
    margin: 0;

    &.optionSelected {
      color: $color-secondary;
      background-color: $color-gray-background;
      margin: 8px 0;
    }

    &:hover {
      color: $color-secondary;
    }
  }

  .indicatorSeparator {
    display: none;
  }

  .indicatorsContainer {
    padding: 0 10px;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-table-border;
    border-radius: 4px;
  }
  .dropdownIndicator {
    color: $color-secondary;
    background-color: $color-white;
    &:hover {
      color: $color-secondary;
    }
  }
  .valueContainer {
    padding-left: 12px;
    padding-right: 12px;
    margin-left: 0;
    margin-right: 0;
    height: 36px;
    border: 1px solid $color-gray-border;
    border-radius: 4px;
  }
  .singleValue {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    color: $color-text;
    margin-left: 0;
    margin-right: 0;
  }
  .menu {
    margin-bottom: 0;
    margin-top: 0;
    width: max-content;
    min-width: 100%;
    box-shadow: none;
    border: 1px solid $color-gray-border;
    border-radius: 0;
  }
  .menuList {
    padding: 0;
  }
}
