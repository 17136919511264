@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  padding: 16px 8px;
  margin-bottom: 8px;
  background-color: $color-white;

  .topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .labels {
      display: flex;
      gap: 4px;
    }
  }

  .productInfo {
    .title {
      font-size: 13px;
      line-height: 120%;
      color: $color-text-dark;
      margin-bottom: 8px;
    }
    padding-bottom: 16px;

    .producer {
      color: $color-text-light;
      font-size: 12px;

      span {
        padding-right: 3px;

        &:nth-child(2) {
          font-weight: 500;
        }

        &:last-child {
          padding-right: 0;
          color: $color-text;
          font-weight: 500;
        }
      }
    }
  }

  .productContent {
    padding: 16px 0;
    display: flex;
    justify-content: space-between;

    // img {
    //   width: 75px;
    //   height: 75px;
    //   object-fit: contain;
    // }

    .priceWrapper {
      text-align: right;
      color: $color-label;
      line-height: 140%;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .netto {
        span {
          &:first-child {
            line-height: 120%;
            font-size: 12px;
            padding-right: 7px;
            text-decoration-line: line-through;
          }
          &:nth-child(2) {
            font-size: 18px;
            font-weight: 700;
            color: $color-text-dark;
            padding-right: 4px;
          }

          &:last-child {
            font-weight: 300;
            color: $color-text-light;
          }
        }
      }
      .brutto {
        span {
          &:last-child {
            font-weight: 300;
            color: $color-text-light;
            padding-left: 3px;
          }
        }
      }

      .options {
        display: flex;
        align-items: center;
        gap: 14px;
        flex-wrap: wrap;
        justify-content: flex-end;

        .addToList {
          display: flex;
          align-items: center;
          svg {
            margin-right: 12px;
            width: 18px;
            height: 20px;
            color: $color-primary;
          }
        }
      }
    }
  }

  .productOrder {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;

    > * {
      width: 120px;
      height: 44px;
      display: flex;
      align-items: center;
    }
  }
}

.dropdownAction {
  display: flex;
  align-items: center;
  gap: 11px;
  font-size: 13px;
  list-style: 120%;
  color: $color-text-dark;

  &.cart {
    margin-top: 12px;
    :first-child {
      color: $color-primary !important;
    }
  }

  &.bin {
    color: $color-bin !important;
    margin-bottom: 12px;
  }

  .shoppingListButton {
    display: flex;
    align-items: center;
    padding: 0;
    border: 0;
    background-color: transparent;
    width: auto;
    margin-left: auto;
    cursor: pointer;
    color: $color-text;
    justify-content: flex-end;

    svg {
      color: $color-primary;
      font-size: 20px;
      margin-right: 8px;
    }
  }
}

.list {
  padding: 0;
  margin: 4px 0;
}

.listItem {
  position: relative;
  display: inline-block;
  width: auto;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  color: $color-text;

  &:not(:last-child) {
    padding-right: 2px;
    margin-right: 4px;

    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 5px);
      right: 0;
      width: 1px;
      height: 10px;
      background-color: $color-text;
    }
  }

  strong {
    display: inline;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: $color-text;

    a {
      text-decoration: none;
      color: $color-secondary;
    }
  }
}
