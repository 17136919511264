@import 'src/theme/color';

.wrapperComponent {
  margin-top: 20px;
  .content {
    background-color: $color-gray-label;
    padding: 20px;
  }

  .item {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    color: $color-text-dark;
    text-decoration: unset;
    margin-bottom: 20px;
    display: block;
  }
}
