@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  .main-image {
    margin-top: 25px;
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    border-radius: 4px;
    margin-bottom: 12px;
    margin-right: 24px;
    position: relative;

    @include breakpoint(md) {
      width: auto;
      height: auto;
    }

    @include breakpoint(md) {
      margin: 0 0 20px 0;
    }

    img {
      display: block;
      width: 100%;
    }

    .searchIcon {
      position: absolute;
      bottom: 8px;
      right: 8px;
      background-color: transparent;
      border: none;
      padding: 0;
      cursor: pointer;
    }
  }

  .thumb {
    padding: 5px;
    max-width: 92px;
    height: 92px;
    background-color: $color-white;
    display: flex !important;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    .image {
      cursor: pointer;
      display: block;
      width: 100%;

      &.active,
      &:hover {
        border-color: $color-gray-border;
      }
    }
  }
}

.label {
  display: inline-block;
  margin: 0 4px 4px 0;
  padding: 6px 10px;
  background: $color-primary;
  font-size: 12px;
  font-weight: 700;
  color: $color-white;

  &.new {
    background: $color-accent;
  }

  &.bestseller {
    background: $color-bestseller;
  }

  &.promotion {
    background: $color-violet;
  }
}

.preview-image {
  margin-bottom: 50px;
  position: relative;
}

.image {
  margin: 0 auto;
  max-width: 90%;
}

.sliderArrow {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid $color-navy;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform: translate(0, -50%);
  z-index: 999;
  cursor: pointer;
}

.prevArrow {
  top: 50%;
  left: 0;
}

.nextArrow {
  top: 50%;
  right: 0;
  svg {
    transform: rotate(180deg);
  }
}

.dots {
  display: flex;
  position: static;
}

.slideDot {
  overflow: hidden;

  &.active {
    border: 1px solid $color-navy;
    border-radius: 4px;
  }
}

.dot {
  padding: 5px;
  width: 92px;
  height: 92px;
  background-color: $color-white;
  display: flex !important;
  justify-content: center;
  align-items: center;

  .image {
    cursor: pointer;
    display: block;
    width: 100%;
  }
}

.miniGallery {
  @include breakpoint(sm) {
    margin: 0 auto;
    margin-bottom: 20px;
  }
}
