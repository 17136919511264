@import 'src/theme/color';

.wrapperComponent {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: $color-secondary;
  cursor: pointer;

  &.isOnFavouriteShoppingList {
    svg {
      g {
        fill: $color-secondary;
      }
    }
  }
}
