@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  width: 100vw;
  padding: 16px 8px;
  margin-bottom: 8px;
  background-color: $color-white;

  img {
    display: block;
  }

  .topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .labels {
      display: flex;
      gap: 4px;
    }
  }

  .productInfo {
    margin: 8px 0;

    .title {
      font-size: 13px;
      line-height: 120%;
      color: $color-text-dark;
      margin-bottom: 8px;
      text-decoration: none;
    }
    padding-bottom: 16px;

    .producer {
      color: $color-text-light;
      font-size: 12px;

      span {
        padding-right: 3px;

        &:nth-child(2) {
          font-weight: 500;
        }

        &:last-child {
          padding-right: 0;
          color: $color-text;
          font-weight: 500;
        }
      }
    }
  }

  .productContent {
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .priceWrapper {
      text-align: right;
      color: $color-label;
      line-height: 140%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .netto {
        span {
          &:first-child {
            line-height: 120%;
            font-size: 12px;
            padding-right: 7px;
            text-decoration-line: line-through;
          }
          &:nth-child(2) {
            font-size: 18px;
            font-weight: 700;
            color: $color-text-dark;
            padding-right: 4px;
          }

          &:last-child {
            font-weight: 300;
            color: $color-text-light;
          }
        }
      }
      .brutto {
        span {
          &:last-child {
            font-weight: 300;
            color: $color-text-light;
            padding-left: 3px;
          }
        }
      }

      .options {
        display: flex;
        align-items: center;
        gap: 14px;
        flex-wrap: wrap;
        justify-content: flex-end;

        .addToList {
          display: flex;
          align-items: center;
          svg {
            margin-right: 12px;
            width: 18px;
            height: 20px;
            color: $color-primary;
          }
        }
      }
    }
  }

  .details {
    display: flex;
    justify-content: space-between;
    text-decoration: unset;
  }

  .list {
    padding: 0;
    margin: 4px 0;
    border-bottom: 1px dashed #dbdee4;
    text-decoration: none;
    display: block;
  }

  .listItem {
    position: relative;
    width: auto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    color: $color-text;

    &:not(:last-child) {
      svg {
        margin-left: 15px;
      }

      margin-right: 14px;
    }

    &:not(.listItemLine) {
      &:first-child {
        &:after {
          display: none;
        }
      }
    }

    strong {
      display: inline;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      color: $color-text;

      a {
        text-decoration: none;
        color: $color-secondary;
      }
    }
  }

  .product-actions {
    margin-bottom: 10px;

    > div {
      height: 44px;
    }
  }

  .unitWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;

      span {
        display: flex;
        align-items: center;
      }

      img {
        margin-right: 4px;

        + span {
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }
      }
    }
  }

  .unitWrapperRight {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;

    span {
      color: #d92d05 !important;
    }

    &.hasQty {
      color: $color-text-dark !important;
    }

    .warehousePrice {
      &.disabled {
        opacity: 0.3;
      }

      &.hasQty {
        color: $color-text-dark !important;
        opacity: 1 !important;
      }
    }
  }

  .warehouseName {
    &.disabled {
      opacity: 0.3;
    }

    img {
      margin: 0 5px 0 0;
      width: unset;
    }
  }

  .warehouseQty {
    &:not(.warehouseQtyLine) {
      font-size: 12px;
    }
  }

  .addToCartButton {
    * {
      color: $color-secondary;
    }
    background-color: transparent;
    border: 1px solid $color-secondary;
  }

  .productOrder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    > div {
      &:first-child {
        min-width: 114px;
        width: 114px;

        > div {
          width: 100%;
        }
      }
    }

    > * {
      width: 100%;
      height: 54px;
      display: flex;
      align-items: center;
    }
  }
}

.dropdownAction {
  display: flex;
  align-items: center;
  gap: 11px;
  font-size: 13px;
  list-style: 120%;
  color: $color-text-dark;

  &.cart {
    margin-top: 12px;
    :first-child {
      color: $color-primary !important;
    }
  }

  &.bin {
    color: $color-bin !important;
    margin-bottom: 12px;
  }
}

.availabilityButton {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: $color-secondary;
  background-color: transparent;
  width: 100%;
  padding: 12px 0;
  border: 1px solid $color-secondary;
  border-radius: 4px;
  cursor: pointer;
  max-height: 44px;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &.isSearch {
    width: 210px;
  }
}

.shoppingListWrapper {
  display: flex;
  margin-top: 8px;

  > div {
    width: 50%;

    &:nth-child(1) {
      display: flex;
      align-items: center;
      padding-right: 8px;

      & > div {
        margin-top: 0;
        margin-left: 0;
        color: $color-secondary;
      }
    }

    &:nth-child(2) {
      padding-left: 8px;
      border-left: 1px solid $color-gray-border;
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }
  }
}
