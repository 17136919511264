@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  background: $color-white;
  width: 100%;
  padding: 12px 92px 12px 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 0px 2px 5px 0px rgba(6, 25, 56, 0.06);

  @media screen and (min-width: 1200px) {
    flex-wrap: nowrap;
  }

  @include breakpoint(md) {
    padding: 0;
    margin: 0 0 4px;
  }
}

.searchFormWrapper {
  width: 300px;

  @include breakpoint(md) {
    display: none;
  }
}

.cartSelectorWrapper {
  margin-left: auto;
  max-width: 400px;
  width: 100%;

  @media screen and (min-width: 1200px) {
    padding-left: 10px;
  }

  @include breakpoint(md) {
    width: 100%;
    border-bottom: 1px solid #dbdee4;
  }
}

.actionMenuWrapper {
  margin-left: 24px;
  display: flex;
  justify-content: space-between;

  @include breakpoint(md) {
    font-size: 12px;
    line-height: 120%;
    width: 100%;
    padding: 12px 8px 12px 4px;
    margin: 0;
  }
}

.confirmationModal {
  width: 100%;
  padding: 0 10px;
}

.cartActionsLabel {
  display: flex;
  align-items: center;

  svg {
    margin: 0 8px;
  }

  @include breakpoint(md) {
    color: $color-text;
    font-size: 12px;
    line-height: 120%;
  }
}

.cartActionsItemLabel {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  @include breakpoint(md) {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    color: $color-text;
  }

  svg {
    @include breakpoint(md) {
      width: 16px;
      height: 20px;
    }
    margin-right: 4px;
  }
}

.confirmationModalActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;

  button + button {
    margin-left: 16px;
  }
}
