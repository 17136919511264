@import 'src/theme/color';
@import 'src/theme/breakpoints';

.shoppingListButtonWrapper {
  display: block;
}

.wrapperComponent {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 100%;
  border: 1px solid transparent;
  background-color: $color-white;
  align-items: center;
  flex-direction: column;
  padding: 16px 8px;

  &.slide {
    .product-title {
      margin-bottom: 0 !important;
    }
  }

  .product-title {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $color-text-light;
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    &.productTitleLine {
      width: auto;
    }

    span {
      margin-bottom: 4px;
    }

    .product-description {
      margin-bottom: 16px;
    }

    .product-params {
      ul {
        margin: 0 0 16px;
        padding: 0;
        list-style: none;

        li {
          display: inline-flex;
          margin-right: 12px;
          margin-bottom: 4px;

          .green {
            color: $color-success;
          }

          .red {
            color: $color-error;
          }
        }
      }

      button {
        border: none;
        background: transparent;
        font-size: 12px;
        color: $color-secondary;
        cursor: pointer;
        padding: 0;
      }
    }
  }

  &.lineWrapper {
    align-items: flex-start;
    flex-direction: row;
    padding: 8px;
  }

  .imageTitleWrapper {
    display: flex;

    &:not(&.line) {
      height: 180px;

      img {
        max-height: 180px;
      }
    }
  }

  .imageStretch {
    width: auto;
    margin-right: -20px;
  }

  .product-image {
    position: relative;
    cursor: pointer;
    background: center center no-repeat transparent;
    background-size: cover;
    flex-shrink: 0;
    width: 100%;

    img {
      display: block;
      width: 100%;
    }

    &.productImageMinimal {
      width: 50%;
      margin-right: 10px;
      height: auto;
    }

    margin-bottom: 8px;
    margin-right: 0;

    &.productImageLine {
      width: 140px;
      margin-bottom: 0;
      margin-right: 27px;

      @media screen and (min-width: 900px) and (max-width: 1000px) {
        width: 65px;
        margin-right: 10px;
      }

      &.isSearch {
        width: 85px;
        height: 85px;
      }
    }
  }

  .product-details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    &:not(&.productDetailsLine) {
      flex: 1;
    }

    &.noActions {
      justify-content: flex-start;
    }

    &.productDetailsLine {
      display: flex;
      flex-direction: row;
      height: 100%;
    }

    .product-title {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $color-text-light;

      width: 100%;

      &.productTitleLine {
        &.isSearch {
          width: auto;
        }

        .title {
          text-decoration: none;

          span {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
          }
        }
      }

      span {
        margin-bottom: 4px;
      }

      .descriptionLabel {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        color: $color-text-dark;
        margin-bottom: 2px;
      }

      .product-description {
        margin-bottom: 16px;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 120%;
      }

      .product-params {
        ul {
          margin: 0 0 16px;
          padding: 0;
          list-style: none;

          li {
            display: inline-flex;
            margin-right: 12px;
            margin-bottom: 4px;

            .green {
              color: $color-success;
            }

            .red {
              color: $color-error;
            }
          }
        }

        button {
          border: none;
          background: transparent;
          font-size: 12px;
          color: $color-secondary;
          cursor: pointer;
          padding: 0;
        }
      }
    }

    .product-actions {
      border-left: none;
      padding-left: 0;
      margin-left: 0;
      width: 100%;

      &.productActionsLine {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-left: 16px;
        border-left: 1px dashed $color-price-border;
        width: unset;

        @media screen and (min-width: 900px) and (max-width: 1000px) {
          padding-left: 0;
        }

        &.isSearch {
          padding-left: 0;
          border-left: unset;
        }
      }

      .unit-selector-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 408px;
        min-height: 44px;

        @media screen and (min-width: 900px) and (max-width: 1000px) {
          max-width: 390px;
        }

        &.unitSelectorWrapperLine {
          width: 408px;

          @media screen and (min-width: 900px) and (max-width: 1000px) {
            width: 390px;
          }
        }

        @include breakpoint(lg) {
          width: 100%;
        }

        &.unitSelectorWrapperMinimal {
          margin: 0 4px 0 0;
        }

        > div {
          width: 100%;
        }

        [class$='-control'] {
          height: 32px;
          padding: 0;
        }
      }

      .unitWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
          display: flex;

          span {
            display: flex;
            align-items: center;
          }

          img {
            margin-right: 4px;

            + span {
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%;
            }
          }
        }

        &.unitWrapperLine {
          > div {
            img + span {
              font-size: 14px;
            }
          }
        }
      }

      .unitWrapperRight {
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;

        span {
          color: #d92d05 !important;
        }

        &.hasQty {
          color: $color-text-dark !important;
        }

        .warehousePrice {
          &.disabled {
            opacity: 0.3;
          }

          &.hasQty {
            color: $color-text-dark !important;
            opacity: 1 !important;
          }
        }
      }

      .availabilityWrapperLine {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .shoppingListButtonWrapperLine {
        margin-left: 0;
        margin-right: 16px;
      }

      .priceWrapperLine {
        margin-top: 0;
      }

      .oldLine {
        margin-right: 37px;
      }

      .toolsLine {
        margin-bottom: 0;
        justify-content: flex-end;
      }
    }

    &.noActions {
      .productActionsLine {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 40px;
      }

      .unit-selector-wrapper {
        margin-bottom: 50px;
        width: 100%;
      }

      .unitSelectorWrapperLine {
        margin-bottom: 0;
        width: 100%;
      }

      .counter-wrapper {
        text-align: left;

        .loginButton {
          text-decoration: none;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          color: #06163a;
          border: unset;
          padding: 0;
          cursor: pointer;
          background-color: transparent;
        }
      }
    }
  }
}

.title {
  display: block;
  width: 100%;
  margin-bottom: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-decoration: none;
  color: $color-text-dark;
  cursor: pointer;
}

.index {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  color: $color-text;

  span {
    font-weight: 500;
  }
}

.producer {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  color: $color-text;

  span {
    color: $color-secondary;
    font-weight: 500;
  }
}

.text {
  width: 100%;
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $color-text-light;

  strong {
    font-weight: 700;
  }
}

.priceWrapper {
  display: flex;
  flex-flow: wrap row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
}

.old {
  display: flex;
  flex-wrap: nowrap;
  margin-right: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-decoration-line: line-through;
  color: $color-text-light;
}

.net {
  display: inline-flex;
  flex-flow: wrap row;
  align-items: flex-end;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  color: $color-text-dark;

  strong {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
    letter-spacing: 0.09px;
    color: $color-text-dark;
    margin-right: 4px;
  }
}

.gross {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  color: $color-text;
}

.tools {
  margin-top: 8px;
  width: 100%;
  display: flex;

  .counter-wrapper {
    min-width: 114px;

    &.counter-wrapper-line {
      max-width: 114px;
      margin-left: 15px;
    }
  }
}

.labels {
  position: absolute;
  top: 4px;
  left: 4px;
  display: flex;
  flex-wrap: wrap;

  &.labelsLine {
    position: static;
  }

  &.isSearch {
    order: -1;
  }
}

.producerLogo {
  position: absolute;
  bottom: 0;
  max-width: 85px;
  width: 100%;

  &.producerLogoLine {
    position: static;

    img {
      display: block;
      width: 100%;
    }
  }
}

.label {
  display: inline-block;
  margin: 0 4px 4px 0;
  padding: 5px 8px;
  border-radius: 4px;
  background: $color-primary;
  font-size: 12px;
  font-weight: 700;
  color: $color-white;

  &.new {
    background: $color-accent;
  }

  &.bestseller {
    background: $color-bestseller;
  }

  &.promotion {
    background: $color-violet;
  }
}

.availabilityButton {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: $color-secondary;
  background-color: transparent;
  width: 100%;
  padding: 12px 0;
  border: 1px solid $color-secondary;
  border-radius: 4px;
  cursor: pointer;
  max-height: 44px;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &.isSearch {
    width: 210px;
  }
}

.warehouseName {
  &.disabled {
    opacity: 0.3;
  }
}

.warehouseQty {
  white-space: nowrap;

  &:not(.warehouseQtyLine) {
    font-size: 12px;
  }
}

.shoppingListWrapper {
  display: flex;
  margin-top: 8px;

  > div {
    width: 50%;

    &:nth-child(1) {
      display: flex;
      align-items: center;
      padding-right: 8px;

      & > div {
        margin-top: 0;
        margin-left: 0;
        color: $color-secondary;
      }
    }

    &:nth-child(2) {
      padding-left: 8px;
      border-left: 1px solid $color-gray-border;
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }
  }
}

.shoppingListWrapperLine {
  width: 100%;
  display: flex;

  > div {
    margin-left: 12px;
    margin-right: 0;
    white-space: nowrap;
  }
}

.list {
  padding: 0;
  margin: 4px 0;
}

.listItem {
  position: relative;
  width: auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  color: $color-text;

  &:not(:last-child) {
    svg {
      margin-left: 15px;
    }

    margin-right: 14px;
  }

  &:not(.listItemLine) {
    &:first-child {
      &:after {
        display: none;
      }
    }
  }

  strong {
    display: inline;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: $color-text;

    a {
      text-decoration: none;
      color: $color-secondary;
    }
  }
}
