@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  width: 100%;

  @include breakpoint(md) {
    padding: 45px 10px;
  }
}

.title {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  letter-spacing: 0.24px;
  padding: 20px 0;

  @include breakpoint(md) {
    text-align: center;
  }
}

.message {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  color: $color-text;
  margin-bottom: 20px;
}

.sliderWrapper {
  position: relative;
  padding: 0 8px;

  .trayBar {
    align-items: center;
    display: flex;
    border: none;
    margin-top: 9px;
    @include breakpoint(md) {
      margin-top: -17px;
    }
    z-index: 8;
    position: relative;
    justify-content: center;
    gap: 12px;

    button {
      padding: 0;
      border-left: none;
      border-right: none;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      border-top: none;
      border-bottom: none;

      &:nth-child(2n + 1) {
        display: none;
      }
      @include breakpoint(md) {
        &:nth-child(n) {
          display: block;
        }
      }

      &:disabled {
        background-color: $color-faq-active;
        width: 9px;
        height: 9px;
      }
    }
  }

  .slide {
    height: 225px;
    outline: none;

    .productWrapper {
      height: 100%;
      padding: 6px;

      > div {
        height: 100%;
      }

      @include breakpoint(md) {
        padding: 0px;
        margin-right: 4px;

        div {
          div {
            flex-wrap: wrap;
            padding: 17px 30px 0 28px;
            height: 114px;
            > span {
              font-size: 18px;
              line-height: 140%;
            }
          }
        }
      }
    }
  }
}
