@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: block;
}

.button {
  position: relative;
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  width: auto;
  cursor: pointer;

  &.active {
    color: $color-primary;

    .countCarts {
      color: $color-primary;
    }
    .price {
      color: $color-primary;
    }
  }

  .icon {
    font-size: 20px;
  }

  .arrow {
    display: block;
    margin-left: 10px;

    &.rotated {
      transform: rotate(180deg);
    }
  }
}

.freeDelivery {
  position: absolute;
  bottom: -50px;
  right: 0;
  padding: 10px 14px;
  border-radius: 4px;
  border: 1px solid #053308;
  background: #d9ffdb;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  color: $color-text-dark;
  white-space: nowrap;
}

.freeDeliveryMissing {
  border: 1px solid #542d08;
  background: #ffebd9;
}

.detailsCart {
  display: block;
  margin-left: 13px;
  margin-right: 16px;
}

.countCarts {
  display: block;
  margin-bottom: 2px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: $color-text;
}

.price {
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: $color-text-dark;
}

.cartWrapper {
  width: 490px;
  padding: 0 20px;

  @include breakpoint(sm) {
    min-width: 300px;
    width: 100%;
  }
}

.cartHeader {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  font-weight: 700;
  font-size: 16px;
  border-bottom: 1px solid $color-gray-background;

  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: $color-text-dark;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.cartBody {
  overflow: auto;

  .emptyCart {
    text-align: center;
    font-weight: 300;
    color: $color-text;
    padding: 50px 16px 75px;

    .title {
      display: block;
      font-size: 16px;
      font-weight: 600;
      color: $color-text-dark;
      line-height: 30px;
    }
  }
}
.info {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  gap: 16px;
  text-decoration: none;
  background-color: $color-order-bg;
  padding: 12px 0;
  color: $color-bestseller;

  svg {
    color: $color-secondary;
  }

  .freeShipping {
    padding-left: 6px;
    font-size: 12px;
    color: $color-text-dark;
  }
}

.cartFooter {
  .total {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    .label {
      font-weight: 700;
    }

    .prices {
      width: 200px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      flex-direction: column;
      color: $color-text-dark;

      .gross {
        font-size: 12px;

        span {
          font-size: 16px;
          font-weight: 700;
        }
      }

      .net {
        font-size: 10px;

        span {
          font-size: 12px;
        }
      }
    }
  }

  .actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;

    a {
      color: $color-primary;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(50% - 10px);
      height: 40px;
      text-decoration: none;
      font-weight: 500;

      &.primary {
        color: $color-white;
        background: $color-primary;
      }
    }
  }
}

.cartTile {
  background-color: $color-gray-background;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 16px;

  .tileHeading {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    color: $color-text-dark;
    margin-bottom: 18px;
  }

  .tileBody {
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    display: flex;
    justify-content: space-between;

    .qty {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;

      & > span:first-child {
        font-weight: 400;
        color: $color-text;
      }
    }

    .price {
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
      color: $color-text;
      text-align: right;
    }

    .priceNet {
      > span {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 25.2px */
        letter-spacing: 0.09px;
        color: $color-text-dark;
      }
    }
  }

  .tileFooter {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 8px;

    .trash {
      cursor: pointer;
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      color: $color-secondary;
    }

    button {
      width: 195px;

      @include breakpoint(sm) {
        width: 120px;
      }
    }
  }
}
